.radio-container {
    background-color: #C0C0C0;
    padding: 10px;
    font-family: "MS Sans Serif", Geneva, sans-serif;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 220px;
    width: 300px;
  }

  .radio-container-mobile {
    background-color: #C0C0C0;
    padding: 10px;
    font-family: "MS Sans Serif", Geneva, sans-serif;
    font-size: 12px;
    align-items: center;
    position: absolute;
    right: 10px;
    width: 300px;
    bottom: 80px;
  }
  
  .station-display {
    margin-bottom: 10px;
    color: black;
  }
  
  .tuning-controls button {
    margin: 0 5px;
    background-color: #C0C0C0;
    border: 1px solid #000;
    cursor: pointer;
  }
  
  .tuning-controls button:hover {
    background-color: #808080;
  }

  .radio-button {
    margin-right: 10px;
  }

  .radio-button.back {
    transform: rotateY(180deg);
  }

