.artworkWindow {
    display: flex;
    flex-direction: column;
    position:absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 10;
}

.artworkContainer {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow-y: auto;
    height: 100%;
}
  
.drawingCanvas {
    border: 2px solid #000; /* Gives the canvas a solid border */
    margin-bottom: 20px; /* Adds some space between the canvas and the controls */
    margin-top: 30px;
  }
  
  .controls {
    margin-top: 20px;
    display: flex;
    gap: 10px; /* Adds some space between each control button */
    flex-wrap: wrap; /* Allows the controls to wrap on smaller screens */
    justify-content: center; /* Centers the controls below the canvas */
  }
  
  .artworkContainer button {
    font-size: 16px; /* Increases the font size for better readability */
    cursor: pointer; /* Changes the cursor to a pointer to indicate a clickable button */
    border: none; /* Removes the default button border */
    border-radius: 5px; /* Rounds the corners of the buttons */
    background-color: #f0f0f0; /* Sets a light gray background color for the buttons */
    transition: background-color 0.3s; /* Adds a smooth transition effect to the background color change */
  }
  
  button:hover {
    background-color: #e0e0e0; /* Changes the background color on hover for visual feedback */
  }
  
  button:disabled {
    background-color: #cccccc; /* Grays out the button when disabled */
    cursor: not-allowed; /* Changes the cursor to indicate the button is disabled */
  }
  
  .colorButton, .sizeButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid transparent; /* Makes the border transparent by default */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Style for color indicators */
  .colorIndicator {
    width: 24px;
    height: 24px;
    border-radius: 4px; /* Slightly rounded corners for the color square */
  }
  
  /* Style for size indicators */
  .sizeIndicator {
    width: 24px;
    height: 24px;
    border-radius: 50%; /* Makes the size indicator circular */
    background-color: black; /* Default color for the size indicator */
  }
  
  /* Highlights the active button */
  .artworkContainer button.active {
    background: #bfbfbf; /* Sets a gray border for the active button */
  }

  .uploadArtworkContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 15px; /* Creates space between each element */
    background-color: #c3c3c3; /* Light background for the form area */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    max-width: 400px; /* Maximum width of the form */
    margin: auto; /* Center the form on the page */
    margin-bottom: 70px;
    margin-top: 30px;
}

.uploadArtworkContainer input[type="text"],
.uploadArtworkContainer input[type="email"],
.uploadArtworkContainer button {
    width: calc(100% - 20px); /* Full width minus padding */
    padding: 10px; /* Padding inside the inputs and button */
    border: 1px solid #ccc; /* Light border for inputs */
    border-radius: 4px; /* Rounded corners for inputs and button */
    box-sizing: border-box; /* Includes padding in the width calculation */
}

.uploadArtworkContainer button {
    background-color: #ff0000; /* Blue background for the button */
    color: white; /* White text color */
    font-weight: bold; /* Makes the button text bold */
    cursor: pointer; /* Changes cursor to pointer to indicate clickability */
    border: none; /* Removes the default border */
}

.uploadArtworkContainer button:hover {
    background-color: #9f0000; /* Darker blue on hover for button */
}

.uploadArtworkContainer input[type="checkbox"] {
    margin-right: 10px; /* Adds space between checkbox and its label */
}

/* Styling for disabled state */
.uploadArtworkContainer input:disabled,
.uploadArtworkContainer button:disabled {
    background-color: #eaeaea; /* Light gray background to indicate disabled state */
    cursor: not-allowed; /* Changes cursor to indicate action not allowed */
}

.galleryContainer {
  overflow-y: auto;
  background-color: #fff;
  height: 100%;
  text-align: left;

}

.artworkShowContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  overflow-y: auto;
}

.artworkItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #c3c3c3;
  padding: 20px;
  border-radius: 20px;
}

.artImage {
  width: 256px;
  height: auto;
  border-radius: 8px;
}

/* Adjust the button styles as needed */
.upvoteButton {
  cursor: pointer;
}