.taskbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to bottom, #c0c0c0, #808080);
  color: white;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  height: 40px;
  z-index: 500;
}

.window-icon {
  cursor: pointer;
  margin-right: 10px;
  padding: 10px 10px;
  border-radius: 3px;
  color: black;
  background-color: #C0C0C0;
  border-left: 2px solid #ffffff;
  border-top: 2px solid #ffffff;
  border-right: 2px solid #808080;
  border-bottom: 2px solid #808080;
  color: black;
  font-family: "MS Sans Serif", Geneva, sans-serif;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
}

.window-icon:active,
.window-icon.pressed {
  border-left: 2px solid #808080;
  border-top: 2px solid #808080;
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  color: black;
}


.start-menu {
  position: absolute;
  bottom: 75px;
  left: 0;
  width: 200px;
  background-color: #C0C0C0;
  border: 1px solid black;
  box-shadow: 2px 2px 0px #000;
  padding: 10px;
  z-index: 1000;
}

.menu-item {
  padding: 5px;
  color: black;
  border: 1px solid transparent;
  margin-bottom: 2px;
}

.menu-item:hover {
  background-color: #000080;
  color: white;
  cursor: pointer;
}

.taskbar-break {
  margin-left: 4px;
  margin-right: 15px;
  color: #C0C0C0;
}

#tooltip {
  position: absolute;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(255,255,255,0.6);
  color: #000000;
  border-radius: 5px;
  pointer-events: none;
  z-index: 100;
  display: none;
  font-size: 14px;
  left: 50%;
  transform: translateX(-50%);
}

.taskbar-time {
  position: absolute;
  right: 30px;
  padding: 5px 20px;
  background-color: #C0C0C0;
  color: #000000;
  border-radius: 5px;
  pointer-events: none;
  border-left: 2px solid #808080;
  border-top: 2px solid #808080;
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;  
}

.message-box {
  position: absolute;
  top: 30%;
  left: 50%;
  padding-bottom: 10px;
  border: 2px solid;
  background-color: #ffffff;
  color: black;
  font-family: "MS Sans Serif", Geneva, sans-serif;
  font-size: 14px;
  width: 300px; 
  z-index: 100;
  transform: translateX(-50%);
}
.message-title-bar {
  background-image: linear-gradient(to right, #ff0000 , #0022ff);
  color: white;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: move;
}
.message-content {
  display: flex;
  align-items: center;
  margin-bottom: 10px; 
  padding: 20px;

}

.message-buttons {
  display: flex;
  justify-content: flex-end;
  margin: 5px;
}

.message-button {
  margin-right: 5px;
  background-color: #C0C0C0;
  border: 1px solid #000;
  color: black;
  padding: 5px 10px;
  cursor: pointer;
  border-right: 2px solid #808080;
  border-bottom: 2px solid #808080;
  border-left: 2px solid #ffffff;
  border-top: 2px solid #ffffff;
}

.message-button:active,
.message-button.pressed {
  border-left: 2px solid #808080;
  border-top: 2px solid #808080;
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  color: black;
}

.honk {
  position: absolute;
  right: 150px;
  margin-right: 5px;
  background-color: #C0C0C0;
  border: 1px solid #000;
  color: black;
  cursor: pointer;
  border-right: 2px solid #808080;
  border-bottom: 2px solid #808080;
  border-left: 2px solid #ffffff;
  border-top: 2px solid #ffffff;
}
.honk:active {
  border-left: 2px solid #808080;
  border-top: 2px solid #808080;
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  color: black;
}

.honkImage {
  margin-top: 10px;
}

.blackout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0, 0, 0); 
  animation: tvShutDown 0.2s backwards; 
  z-index: 10000;
  transition: 1s;
}

#powerbutton {
  opacity: 0;
  transition: 2s;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  cursor: pointer;
}

@keyframes tvShutDown {
  0% {
    transform: scale(1);
    opacity: 1;
    clip-path: inset(0);
    background: #000;
  }
  100% {
    transform: scale(0.95); 
    clip-path: inset(50% 0); 
    opacity: 0; 
  }
}