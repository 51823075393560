.mainContentBrowser {
  position: absolute;
  border: 2px solid #000;
  background-color: #c0c0c0;
  width: 80%;
  height: 60%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.titleBar {
  background-image: linear-gradient(to right, red , blue);
  color: white;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: move;
}

.mainContentBrowser::-webkit-scrollbar {
  width: 16px;
  background-color: #C0C0C0;
}

.mainContentBrowser::-webkit-scrollbar-thumb {
  background-color: #808080;
  border: 3px solid #C0C0C0;
  border-radius: 0px;
}

.mainContentBrowser::-webkit-scrollbar-button {
  background-color: #C0C0C0;
  border: 1px solid #808080;
}

.mainContentBrowser::-webkit-scrollbar-corner {
  background-color: black;
}


.mainContentBrowser {
  font-family: 'MS Sans Serif', Geneva, sans-serif;
  color: #000000;
  background-color: #C0C0C0;
  scrollbar-width: auto;
  scrollbar-color: #808080 #C0C0C0;
}



.windowControls {
  display: flex;
}

.windowControls button {
  background-color: #c0c0c0;
  border: 1px solid #fff;
  color: black;
  width: 20px;
  height: 20px;
  font-size: 10px;
  margin-left: 2px;
  cursor: pointer;
}

.windowControls button:hover {
  background-color: #fff;
}

.content {
  display: flex;
  justify-content: center;
  background-color: #fff;
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto; 
}

.content p {
  font-size: 18px;
}

.content iframe {
  margin-top: 20px;
  margin-bottom: 20px;
}

.content img {
  margin-top: 20px;
  margin-bottom: 20px;
}

.article {
  width: 70%;
  left: 50%;

}

.searchBar {
  display: flex;
  padding: 5px;
  background-color: #c0c0c0;
  border-bottom: 2px solid #000;
}

.searchBar input {
  flex-grow: 1;
  padding: 2px 5px;
  margin-right: 5px;
}

.searchBar button {
  padding: 2px 5px;
  background-color: #00007f;
  color: white;
  border: none;
  cursor: pointer;
}

.searchBar button:hover {
  background-color: #0000ff;
}

.resizeHandle.right {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  cursor: ew-resize;
}

.resizeHandle.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
  cursor: ns-resize;
}
