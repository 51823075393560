.App {
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  height: 100%;
  position: fixed; 
  top: 0;
  left: 0;
  z-index: -1; 
}

